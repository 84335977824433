import axios, { AxiosResponse } from 'axios';
import { convertUnixTimestampToDateString } from '../constants/utils';

// For local dev this env var is set in the npm scripts (set this to be your dev WSATools domain name)
// For prod deployment everything is under the same domain name
let BASE_URL = '';
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  BASE_URL = process.env.REACT_APP_DEV_SERVER!;
}

const WARRANTY_LIST_FIELD = 'warrantyList';
const CLAIM_HISTORY_FIELD = 'claimHistory';
const BINDING_HISTORY_FIELD = 'bindingHistory';

const CORAL_WARRANTY_DATE_FIELDS = ['startDate', 'expirationDate', 'purchaseDate'];
const CORAL_BINDING_DATE_FIELDS = ['warrantyExpirationDate', 'bindTime', 'unBindTime'];
const CORAL_CLAIM_DATE_FIELDS = ['claimDate'];

// CID is required to be non-empty for some APIs but not actually necessary
const DEFAULT_CUSTOMER_ID = 'ATVPDKIKX0DER';

export interface ClaimOption {
  isException: boolean;
  name: string;
  replacementCharge: string;
  replacementEligibility: boolean;
  replacementType: string;
}

export interface DeviceIdentifier {
  serialNumber: string;
  type: string;
}

export enum ClaimTypeCode {
  MANUFACTURER_INDUCED_DEFECT = 'MANUFACTURER_INDUCED_DEFECT',
  CUSTOMER_INDUCED_DEFECT = 'CUSTOMER_INDUCED_DEFECT'
}

export interface GetWarrantiesByDeviceRequest {
  deviceSerialNumber: string;
  deviceType: string;
  includeClaims: boolean;
  includeUnboundEW: boolean;
  includePreviouslyBoundWarranties: boolean;
  customerId?: string;
}

export interface GetWarrantyClaimCodeRequest {
    device: DeviceIdentifier;
    claimTypeCode: ClaimTypeCode;
    claimMarketplaceId: string;
    customerId?: string;
    // There are many other optional parameters not supported in WSA Tools
}

export interface GetWarrantyClaimCodeResponse {
    warrantyClaimCode: WarrantyClaimEligibilityCode,
    overrideWarrantyClaimCodes: WarrantyClaimEligibilityCode[]
}

export interface GetWarrantyClaimOptionsRequest {
  device: DeviceIdentifier;
  claimTypeCode: ClaimTypeCode;
  claimMarketplaceId: string;
  customerId?: string;
}

export interface WarrantyClaimEligibilityCode {
    name: string,
    replacementEligibility?: boolean,
    replacementCharge?: string,
    isException?: boolean,
    replacementType: string,
    ReturnableType?: string
}

export interface GetExtendedWarrantiesByOrderUnitRequest {
  warrantyOrderUnitRecord: {
    orderId: string;
    instance: number;
  };
}

export interface GetExtendedWarrantiesByCustomerIdRequest {
    customerId: string
}

export interface BackfillLimitedWarrantyRequestV2 {
  deviceSerialNumber: string;
  deviceType: string;
}

export interface TransferLimitedWarrantyForCanceledClaimRequest {
  warrantyId: string;
  deviceSerialNumber: string;
  deviceType: string;
}

export interface BindExtendedWarrantyRequest {
  warrantyId: string;
  deviceSerialNumber: string;
  deviceType: string;
  marketplaceId: string;
}

export async function getWarrantiesByDevice(data: GetWarrantiesByDeviceRequest): Promise<AxiosResponse> {
  return axios
    .post(
      `${BASE_URL}/api/GetWarrantiesByDevice`,
      {
        ...data,
        customerId: data.customerId || DEFAULT_CUSTOMER_ID
      },
      { validateStatus: () => true }
    )
    .then(convertDateFieldsToReadableStrings);
}

export async function getWarrantyClaimCode(data: GetWarrantyClaimCodeRequest): Promise<AxiosResponse> {
    return axios.post(
       `${BASE_URL}/api/GetWarrantyClaimCode`,
       {
           ...data,
           customerId: data.customerId || DEFAULT_CUSTOMER_ID
       },
       { validateStatus: () => true }
    );
}

export async function getExtendedWarrantiesByOrderUnit(
  data: GetExtendedWarrantiesByOrderUnitRequest
): Promise<AxiosResponse> {
  return axios
    .post(`${BASE_URL}/api/GetExtendedWarrantiesByOrderUnit`, data, { validateStatus: () => true })
    .then(convertDateFieldsToReadableStrings);
}

export async function getExtendedWarrantiesCustomerId(
    data: GetExtendedWarrantiesByCustomerIdRequest
): Promise<AxiosResponse> {
    return axios
        .post(`${BASE_URL}/api/GetExtendedWarrantiesByCustomerId`, data, { validateStatus: () => true })
        .then(convertDateFieldsToReadableStrings);
}

export async function getDeviceTypeForDsn(dsn: string): Promise<AxiosResponse> {
  return axios.post(`${BASE_URL}/api/GetDeviceTypeForDsn`, dsn);
}

export async function backfillLimitedWarrantyV2(data: BackfillLimitedWarrantyRequestV2) {
  return axios.post(`${BASE_URL}/api/BackfillLimitedWarrantyV2`, data, { validateStatus: () => true });
}

export async function transferLimitedWarrantyForCanceledClaim(data: TransferLimitedWarrantyForCanceledClaimRequest) {
  return axios.post(`${BASE_URL}/api/TransferLimitedWarrantyForCanceledClaim`, data, { validateStatus: () => true });
}

export async function bindExtendedWarranty(data: BindExtendedWarrantyRequest) {
  return axios.post(`${BASE_URL}/api/BindExtendedWarranty`, data, { validateStatus: () => true });
}

const convertDateFieldsToReadableStrings = (axiosResponse: AxiosResponse): AxiosResponse => {
  const { data } = axiosResponse;
  if (data !== undefined && data !== "" && WARRANTY_LIST_FIELD in data) {
    data[WARRANTY_LIST_FIELD].forEach((warranty: any) => {
      applyFormatterToDataFields([warranty], CORAL_WARRANTY_DATE_FIELDS, convertUnixTimestampToDateString);

      if (CLAIM_HISTORY_FIELD in warranty) {
        applyFormatterToDataFields(
          warranty[CLAIM_HISTORY_FIELD],
          CORAL_CLAIM_DATE_FIELDS,
          convertUnixTimestampToDateString
        );
      }

      if (BINDING_HISTORY_FIELD in warranty) {
        applyFormatterToDataFields(
          warranty[BINDING_HISTORY_FIELD],
          CORAL_BINDING_DATE_FIELDS,
          convertUnixTimestampToDateString
        );
      }
    });
  }
  return axiosResponse;
};

function applyFormatterToDataFields(objects: object[], dataFields: string[], formatter: (input: any) => any): void {
  objects.forEach((object: any) => {
    dataFields.forEach((dataField) => {
      if (dataField in object) {
        object[dataField] = formatter(object[dataField]);
      }
    });
  });
}
