import { Button, Col, Container, Form, Modal, Row, Spinner } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import APIResultViewer from '../components/APIResultViewer';
import { ColumnDescription } from 'react-bootstrap-table-next';
import './ExtendedWarrantySearchPage.css'
import {
  bindExtendedWarranty,
  getDeviceTypeForDsn,
  getExtendedWarrantiesByOrderUnit,
  getExtendedWarrantiesCustomerId
} from '../api/WsaToolsFacade';
import {
  capitalizeAndTrim,
  getQueryParamAsNumberOrUseFallback,
  getQueryParamAsStringOrUseFallback
} from '../constants/utils';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { addNotification, clearNotifications, Severity } from '../actions/notificationsSlice';
import {
  dispatchRequestErrorNotification,
  ERROR_MESSAGES,
  RETRY_MESSAGES,
  SUCCESS_MESSAGES
} from '../constants/notificationMessages';
import JSONText from '../components/JSONText';
import { ChevronLeft, QuestionCircle } from 'react-bootstrap-icons';
import { useSearchParams } from 'react-router-dom';
import MarketplaceSelector from '../components/MarketplaceSelector';

const EXTENDED_WARRANTY_COLUMNS: ColumnDescription[] = [
  { dataField: 'id', text: 'Warranty Id', sort: true },
  { dataField: 'statusCode', text: 'Status', sort: true },
  {
    dataField: 'bindingHistory',
    text: 'Actively Bound Device(s)',
    formatter: (bindings) => {
      const activelyBoundDevices = bindings
        .filter((binding: any) => binding.unBindTime === undefined)
        .map((binding: any) => binding.device);
      return <JSONText data={activelyBoundDevices} />;
    }
  },
  { dataField: 'warranty3PId', text: 'Warranty 3P Id', sort: true },
  {
    dataField: 'purchaseDate',
    text: 'Purchase Date',
    sort: true,
    style: { minWidth: '250px' }
  },
  {
    dataField: 'cancellationDate',
    text: 'Cancellation Date',
    sort: true,
    style: { minWidth: '250px' }
  },
  {
    dataField: 'startDate',
    text: 'Start Date',
    sort: true,
    style: { minWidth: '250px' }
  },
  {
    dataField: 'expirationDate',
    text: 'Expiration Date',
    sort: true,
    style: { minWidth: '250px' }
  },
  { dataField: 'purchaseCustomerId', text: 'Purchase Customer Id', sort: true },
  {
    dataField: 'orderUnitRecord',
    text: 'Order Unit Record',
    sort: true,
    formatter: (data) => <JSONText data={data} />
  },
  { dataField: 'saleChannelCode', text: 'Sale Channel Code', sort: true },
  { dataField: 'sellerId', text: 'Seller Id', sort: true }
];

export interface ExtendedWarranty {
  id: string;
  warranty3pId: string;
  statusCode: string;
  startDate: Date;
  expirationDate: Date;
  saleChannelCode: string;
  purchaseCustomerId: string;
  sellerId: string;
  orderUnitRecord: object;
  bindingHistory: any[];
}

export interface GetExtendedWarranties {
  warranties: ExtendedWarranty[];
  apiCallStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
}

const NEGATIVE_ORDER_INSTANCE_HINT = 'Order instance cannot be a negative number';
const EXTENDED_WARRANTY_PAGE_WIKI = 'https://w.amazon.com/bin/view/WarrantyServices/WSATools/#HExtendedWarrantySearch';
const ORDER_ID_QUERY_PARAM = 'orderId';
const ORDER_INSTANCE_QUERY_PARAM = 'orderInstance';
const CUSTOMER_ID_QUERY_PARAM = 'customerId';

export default function ExtendedWarrantySearchPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const { userAlias } = useAppSelector((state) => state.user);

  const [isExtendedWarrantyIdSearchDisabled, setIsExtendedWarrantyIdSearchDisabled] = useState<boolean>(false);
  const [isCustomerIdSearchDisabled, setIsCustomerIdSearchDisabled] = useState<boolean>(false);
  const [customerId, setCustomerId] = useState<string>('');
  const [orderId, setOrderId] = useState<string>('');
  const [orderInstance, setOrderInstance] = useState<number>(0);
  const [extendedWarranties, setExtendedWarranties] = useState<GetExtendedWarranties>({
    warranties: [],
    apiCallStatus: 'idle'
  });
  const [deviceSerialNumberToBind, setDeviceSerialNumberToBind] = useState<string>('');
  const [deviceTypeToBind, setDeviceTypeToBind] = useState<string>('');
  const [fallbackMarketplaceId, setFallbackMarketplaceId] = useState<string>('');
  const [selectedExtendedWarrantyIdToBind, setSelectedExtendedWarrantyIdToBind] = useState<string>('');
  const [showBindEwInputModal, setShowBindEwInputModal] = useState(false);
  const handleCloseBindEwInputModal = () => setShowBindEwInputModal(false);
  const [showBindEwConfirmModal, setShowBindEwConfirmModal] = useState(false);
  const handleCloseBindEwConfirmModal = () => setShowBindEwConfirmModal(false);

  useEffect(() => {
    if (!deviceSerialNumberToBind) {
      return;
    }

    const deviceSerialNumberCopy = deviceSerialNumberToBind.slice();
    getDeviceTypeForDsn(deviceSerialNumberCopy)
      .then(({ status, data }) => {
        if (status === 200 && data.deviceSerialNumber === deviceSerialNumberCopy) {
          // Only overwrite the deviceType field if "getDeviceTypeForDsn" returns a valid value
          // And the DSN hasn't changed since the request was made
          setDeviceTypeToBind(data.deviceType);
        }
      })
      // Best effort to get a deviceType for a given dsn, ignore errors
      .catch((e) => {});
  }, [deviceSerialNumberToBind]);

  useEffect(() => {
    setOrderId(getQueryParamAsStringOrUseFallback(searchParams, ORDER_ID_QUERY_PARAM, ''));

    const orderInstanceQueryParamNum = getQueryParamAsNumberOrUseFallback(searchParams, ORDER_INSTANCE_QUERY_PARAM, 0);
    setOrderInstance(orderInstanceQueryParamNum >= 0 ? orderInstanceQueryParamNum : 0);
  }, [searchParams]);

  const isLoading = () => {
    return extendedWarranties.apiCallStatus === 'loading';
  };

  const isFormValid = () => {
    return (!!orderId && orderInstance >= 0) || !!customerId;
  };

  const handleQuery = () => {
    if (!isFormValid() || isLoading()) {
      if (orderInstance < 0) {
        dispatch(addNotification({ message: NEGATIVE_ORDER_INSTANCE_HINT, severity: Severity.INFO }));
      }
      return;
    }

    setSearchParams({
      [ORDER_ID_QUERY_PARAM]: orderId,
      [ORDER_INSTANCE_QUERY_PARAM]: orderInstance.toString(),
      [CUSTOMER_ID_QUERY_PARAM]: customerId
    });

    setExtendedWarranties({
      ...extendedWarranties,
      apiCallStatus: 'loading'
    });
    dispatch(clearNotifications());

    if (!!orderId && orderInstance >= 0) {
      getExtendedWarrantiesByOrderUnitWrapper(orderId, orderInstance)
    }
    else
    {
      getExtendedWarrantiesCustomerIdWrapper(customerId)
    }
  };

  useEffect(() =>  {
    if (orderId !== '' || orderInstance !== 0) {
      setIsCustomerIdSearchDisabled(true);
    }
    else
    {
      setIsCustomerIdSearchDisabled(false)
    }

  }, [orderInstance, orderId])

  useEffect(() => {
    if (customerId !== '') {
    setIsExtendedWarrantyIdSearchDisabled(true);
  }
  else
  {
    setIsExtendedWarrantyIdSearchDisabled(false)
  }}, [customerId])


  function getExtendedWarrantiesCustomerIdWrapper(customerId: string) {
    getExtendedWarrantiesCustomerId({
      customerId: customerId

    })
        .then(({status, data}) => {
          if (status === 200) {
            setExtendedWarranties({
              warranties: !data ? [] : data.warrantyList,
              apiCallStatus: 'succeeded'
            });
            return;
          } else if (status === 504) {
            dispatch(addNotification({message: RETRY_MESSAGES.TIMEOUT, severity: Severity.INFO}));
          } else if (status === 403) {
            dispatch(addNotification({message: ERROR_MESSAGES.UNAUTHORIZED, severity: Severity.ERROR}));
          } else {
            dispatch(addNotification({message: data, severity: Severity.ERROR}));
          }
          setExtendedWarranties({
            warranties: [],
            apiCallStatus: 'failed'
          });
        })
        .catch((err) => {
          console.error(err);
          dispatch(addNotification({message: RETRY_MESSAGES.DEFAULT, severity: Severity.ERROR}));
          setExtendedWarranties({
            warranties: [],
            apiCallStatus: 'failed'
          });
        });
  }

  function getExtendedWarrantiesByOrderUnitWrapper(orderId: string, orderInstance: number) {
      getExtendedWarrantiesByOrderUnit({
        warrantyOrderUnitRecord: {
          orderId: orderId,
          instance: orderInstance
        }
      })
          .then(({status, data}) => {
            if (status === 200) {
              setExtendedWarranties({
                warranties: !data ? [] : data.warrantyList,
                apiCallStatus: 'succeeded'
              });
              return;
            } else if (status === 504) {
              dispatch(addNotification({message: RETRY_MESSAGES.TIMEOUT, severity: Severity.INFO}));
            } else if (status === 403) {
              dispatch(addNotification({message: ERROR_MESSAGES.UNAUTHORIZED, severity: Severity.ERROR}));
            } else {
              dispatch(addNotification({message: data, severity: Severity.ERROR}));
            }
            setExtendedWarranties({
              warranties: [],
              apiCallStatus: 'failed'
            });
          })
          .catch((err) => {
            console.error(err);
            dispatch(addNotification({message: RETRY_MESSAGES.DEFAULT, severity: Severity.ERROR}));
            setExtendedWarranties({
              warranties: [],
              apiCallStatus: 'failed'
            });
          });
    }

  const handleBindExtendedWarranty = () => {
    bindExtendedWarranty({
      warrantyId: selectedExtendedWarrantyIdToBind,
      deviceSerialNumber: deviceSerialNumberToBind,
      deviceType: deviceTypeToBind,
      marketplaceId: fallbackMarketplaceId
    })
      .then(({ status, data, headers }) => {
        if (status === 200) {
          dispatch(addNotification({ message: SUCCESS_MESSAGES.BIND_EW, severity: Severity.SUCCESS }));
        } else {
          dispatchRequestErrorNotification(status, data, headers);
        }
      })
      .catch((err) => {
        console.error(err);
        dispatch(addNotification({ message: RETRY_MESSAGES.DEFAULT, severity: Severity.ERROR }));
      });
    setShowBindEwConfirmModal(false);
  };

  const handleOnReset = () => {
    setOrderId('');
    setOrderInstance(0);
    setCustomerId('');
    setExtendedWarranties({
      warranties: [],
      apiCallStatus: 'idle'
    });
    setIsExtendedWarrantyIdSearchDisabled(false);
    setIsCustomerIdSearchDisabled(false);
    setDeviceTypeToBind('');
    setDeviceSerialNumberToBind('');
    dispatch(clearNotifications());
    setSearchParams({});
  };

  const handleFormInputKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      handleQuery();
    }
  };

  const renderBindExtendedWarrantyButton = (cell: any, row: any) => {
    return (
      <Button
        onClick={() => {
          setShowBindEwInputModal(true);
          setDeviceSerialNumberToBind('');
          setDeviceTypeToBind('');
          setSelectedExtendedWarrantyIdToBind(row.id);
        }}
        disabled={row.statusCode !== 'ACTIVE'}
      >
        Bind Extended Warranty
      </Button>
    );
  };

  const renderQueryResultViewer = () => {
    if (extendedWarranties.apiCallStatus === 'succeeded') {
      return (
        <React.Fragment>
          <hr className="ms-3 me-3" />
          <APIResultViewer
            title={'Extended Warranties'}
            data={extendedWarranties.warranties}
            keyField={'warranty3PId'}
            columns={[
              {
                dataField: 'bindExtendedWarranty',
                text: '',
                formatter: renderBindExtendedWarrantyButton
              },
              ...EXTENDED_WARRANTY_COLUMNS
            ]}
          />
        </React.Fragment>
      );
    }
    return <React.Fragment />;
  };

  return (
    <div>
      <Container fluid={true}>
        <Row>
          <h2>
            EXTENDED WARRANTY SEARCH &nbsp;
            <a href={EXTENDED_WARRANTY_PAGE_WIKI} target="blank" className="d-inline-flex align-bottom mb-1">
              <QuestionCircle />
            </a>
          </h2>
        </Row>
        <Row className="m-auto justify-content-center">
          <Col sm={8}>
            <Container className="text-start h-fill">
              <Form>
                <Row className="d-flex justify-content-center">
                  <Col sm={4}>
                    <div className="drawBox">
                    <Form.Group className="mb-3" controlId="ExtendedWarrantySearchForm">
                      <Form.Label>Extended Warranty Order Id: </Form.Label>
                      <Form.Control
                        type="text"
                        value={orderId}
                        onKeyPress={handleFormInputKeyPress}
                        onChange={(e) => {
                          setOrderId(capitalizeAndTrim(e.target.value));
                        }}
                        disabled={isExtendedWarrantyIdSearchDisabled}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="ExtendedWarrantySearchForm">
                      <Form.Label>Extended Warranty Order Instance: </Form.Label>
                      <Form.Control
                        type="number"
                        value={orderInstance}
                        min={0}
                        onKeyPress={handleFormInputKeyPress}
                        onChange={(e) => {
                          setOrderInstance(!e.currentTarget.value ? 0 : parseInt(e.currentTarget.value));
                        }}
                        disabled={isExtendedWarrantyIdSearchDisabled}
                      />
                    </Form.Group>
                      </div>
                    <span className="mb-3 centeredOrStatement">OR</span>
                    <div className="drawBox">
                    <Form.Group className="mb-3" controlId="ExtendedWarrantySearchForm">
                      <Form.Label>Encrypted Customer ID: </Form.Label>
                      <Form.Control
                          type="text"
                          value={customerId}
                          onKeyPress={handleFormInputKeyPress}
                          onChange={(e) => {
                            setCustomerId(e.target.value);
                          }}
                          disabled={isCustomerIdSearchDisabled}
                      />
                    </Form.Group>
                    </div>
                  </Col>
                </Row>
                <Row className="addPadding">
                  <Col className="d-flex justify-content-center">
                    {isLoading() ? (
                      <React.Fragment />
                    ) : (
                      <Button className="btn-md me-2" onClick={handleOnReset}>
                        Reset
                      </Button>
                    )}
                    <Button className="btn-md" onClick={handleQuery} disabled={!isFormValid() || isLoading()}>
                      {isLoading() ? <Spinner animation="border" size="sm" /> : <span>Search</span>}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Container>
          </Col>
        </Row>
        <Row>
          <Col className="m-auto">{renderQueryResultViewer()}</Col>
        </Row>
      </Container>

      <Modal show={showBindEwInputModal} onHide={handleCloseBindEwInputModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Input Device to Bind</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={!deviceSerialNumberToBind || !deviceTypeToBind}>
            <Form.Group className="mb-3" controlId="DeviceWarrantySearchForm">
              <Form.Label>Device Serial Number: </Form.Label>
              <Form.Control
                required
                type="text"
                value={deviceSerialNumberToBind}
                onChange={(e) => {
                  setDeviceSerialNumberToBind(capitalizeAndTrim(e.target.value));
                }}
              />
              <Form.Control.Feedback type="invalid">Please enter a DSN.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="DeviceWarrantySearchForm">
              <Form.Label>Device Type: </Form.Label>
              <Form.Control
                required
                type="text"
                value={deviceTypeToBind}
                onChange={(e) => {
                  setDeviceTypeToBind(capitalizeAndTrim(e.target.value));
                }}
              />
              <Form.Control.Feedback type="invalid">Please enter a Device Type.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="DeviceWarrantySearchForm">
              <Form.Label>Device Purchase Marketplace Id (optional): </Form.Label>
              <MarketplaceSelector
                selectedOption={fallbackMarketplaceId}
                formValueSetter={(inputText) => {
                  setFallbackMarketplaceId(capitalizeAndTrim(inputText));
                }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseBindEwInputModal}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleCloseBindEwInputModal();
              setShowBindEwConfirmModal(true);
            }}
            disabled={!deviceSerialNumberToBind || !deviceTypeToBind}
          >
            Next
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showBindEwConfirmModal} onHide={handleCloseBindEwConfirmModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Confirm Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          I ({userAlias}) know what I'm doing and want to bind dsn: ({deviceSerialNumberToBind}), deviceType: (
          {deviceTypeToBind}){fallbackMarketplaceId.length === 0 ? ' ' : `, marketplaceId: (${fallbackMarketplaceId}) `}
          to extended warranty id: ({selectedExtendedWarrantyIdToBind})
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row>
              <Col>
                <Button
                  variant="outline-secondary"
                  onClick={() => {
                    handleCloseBindEwConfirmModal();
                    setShowBindEwInputModal(true);
                  }}
                  className="me-3 align-left"
                >
                  <ChevronLeft /> Back
                </Button>
              </Col>
              <Col className="d-flex justify-content-end">
                <Button variant="secondary" onClick={handleCloseBindEwConfirmModal} className="me-2">
                  Cancel
                </Button>
                <Button variant="primary" onClick={handleBindExtendedWarranty}>
                  Bind Extended Warranty
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
